body {
  margin: 0;
  border: 0;
  padding: 0;
}

.container-fluid {
  margin: 0;
  border: 0;
  padding: 0;
}

form {
  padding: 10px;
  margin-left: 20px;
}

.header {
  font-family: 'Merriweather', serif;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url('../../assets/img/legallibrary.jpeg');
  background-size: cover;
  width: 100vw;
  height: 30vh;
  box-shadow: inset 0 0 0 2000px rgba(41, 41, 41, 0.3);
  color: lightgray;
}

h2 {
  margin-block-end: 0;
}

h3 {
  margin-block-start: 0;
}

.lead {
  padding: 10px;
}

.parallax {
  /* The image used */
  background-image: url("../img/legallibrary.jpeg");

  /* Set a specific height */
  min-height: 500px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Page Sections */

.ps-light{
  background: whitesmoke;
  height: 550px;
  width: 100%;
}

.ps-dark{
  background: lightgrey;
  /* height: 550px; */
  width: 100%;
}
